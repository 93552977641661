import './SearchLyceum.css'
import axios from 'axios'
import IconMenu from '../../components/IconMenu'
import TitlePage from '../../components/TitlePage'
import Text from '../../components/Text'
import blackHeart from '../../img/cora_preto.png'
import iconPrice from '../../icons/price.png'
import search from '../../icons/search.png'
import exit from '../../icons/exit.png'
import iconId from '../../icons/id.png'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useState, useEffect } from 'react'
import ClientData from '../../components/ClientData'
import ResumeData from '../../components/ResumeData'
import iconRegister from '../../icons/register.png'
import iconCalendar from '../../icons/calendar.png'
import iconSchool from '../../icons/school.png'
import Img from '../../components/Img'
import logoWhite from '../../img/young_branco.png'
import Icon from '../../components/Icon'
import iconPhone from '../../icons/phone.png'
import iconMail from '../../icons/mail.png'
import { useAuth } from '../../context/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import LoadingImageRotate from '../../components/LoadingImageRotate'
import loadingImageFromPage from '../../img/loading_branco.png'
import { Link } from 'react-router-dom'
import iconTabelaPrice from '../../img/pricetag.png'
import imgPerformance from '../../img/img123.png'
import MenuGeneral from '../../components/MenuGeneral'


const SearchLyceum = () => {

    const { token, logout } = useAuth()
    const [registerClient, setRegisterClient] = useState(null)
    const [returnSearchRegistration, setReturnSearchRegistration] = useState(null)
    const [loadingSearchRegistration, setLoadingSearchRegistration] = useState(false)
    const [loadingSearchGet, setLoadingSearchGet] = useState(false)
    const [content, setContent] = useState(false)
    const [contentSelected, setContentSelected] = useState(false)
    const [cpfSearchPage, setCpfSearchPage] = useState(null)
    const [nameSearchPage, setNameSearchPage] = useState(null)
    const [registrationSearchPage, setRegistrationSearchPage] = useState(null)
    const [consultIdSearchPage, setConsultIdSearchPage] = useState(null)
    const [selectedDebtIndex, setSelectedDebtIndex] = useState(null)
    const [arrayJuros, setArrayJuros] = useState([])
    const [selectedJuros, setSelectedJuros] = useState(null)
    const [selectedIdInternal, setSelectedIdInternal] = useState(null)
    const [valorSemEncargos, setValorSemEncargos] = useState(null)
    const [valorEncargos, setValorEncargos] = useState(null)
    const [updateValueTotalNegociation, setUpdateValueTotalNegociation] = useState(null)
    const [returnNegociation, setReturnNegociation] = useState({})
    const [returnNegociationDiv, setReturnNegociationDiv] = useState(false)
    const [returnAcordo, setReturnAcordo] = useState({})
    const [returnAcordoDiv, setReturnAcordoDiv] = useState(false)
    const [selectedInternalSimulationId, setSelectedInternalSimulationId] = useState(null)
    const [simulationId, setSimulationId] = useState(null)
    const [dueDay, setDueDay] = useState(null)
    const [emailNotify, setEmailNotify] = useState(null)
    const [valorEncargoSemDesconto, setValorEncargoSemDesconto] = useState(null)
    const [valorEncargoNegociado, setValorEncargoNegociado] = useState(null)
    const [valorDescontoEncargo, setValorDescontoEncargo] = useState(null)
    const [loadingAcordo, setLoadingAcordo] = useState(null)
    const [selectedPaymentValue, setSelectedPaymentValue] = useState(null)
    const [selectedPaymentValueEntry, setSelectedPaymentValueEntry] = useState(null)
    const [cobrancaReturnSelected, setCobrancaReturnSelected] = useState(null)
    const [detalhesRegistro, setDetalhesRegistro] = useState(null)
    const [arrayCobrancaSelected, setArrayCobrancaSelected] = useState({})
    const [infoCobranca, setInfoCobranca] = useState(null)
    const [infoNomeCurso, setInfoNomeCurso] = useState(null)
    const [infoDtVenc, setInfoDtVenc] = useState(null)
    const [infoValorReal, setInfoValorReal] = useState(null)
    const [infoValorMulta, setInfoValorMulta] = useState(null)
    const [infoValorJuros, setInfoValorJuros] = useState(null)
    const [infoValorEncargo, setInfoValorEncargo] = useState(null)
    const [infoValorEncargoBolsa, setInfoValorEncargoBolsa] = useState(null)
    const [infoValorFinal, setInfoValorFinal] = useState(null)
    const [infoCelular, setInfoCelular] = useState(null)
    const [infoEmail, setInfoEmail] = useState(null)
    const [infoDiasAtraso, setInfoDiasAtraso] = useState(null)
    const [infoArrayDetalhesParcela, setInfoArrayDetalhesParcela] = useState(null)
    const [infoTitularSearchPage, setInfoTitularSearchPage] = useState(null)
    const [inputEntryValue, setInputEntryValue] = useState(0.0)
    const [vencimentoSelecionado, setVencimentoSelecionado] = useState(null)
    const [divPaymentEntry, setDivPaymentEntry] = useState(false)
    const [divInfosParcelasConsolidado, setDivInfosParcelasConsolidado] = useState(false)
    
    

    //configuração de permanencia de login
    if (!token) {
        return <Navigate to="/" />
    }

    const navigate = useNavigate()
    useEffect(() => {
        //console.log('DEV(useEffect) - inputEntryValue:', inputEntryValue)
        if (inputEntryValue !== null && inputEntryValue !== 0) {
          setDivPaymentEntry(true)
        } else {
          setDivPaymentEntry(false)
        }
      }, [inputEntryValue])


    useEffect(() => {
        //console.log('DEV(useEffect)  - detalhesRegistro:', detalhesRegistro)
        if (detalhesRegistro){
            

            setInfoValorFinal(null)
            setInfoValorReal(null)
            setInfoValorMulta(null)
            setInfoValorJuros(null)
            setInfoValorEncargo(null)
            setInfoValorEncargoBolsa(null)
            setInfoCobranca(null)
            setInfoNomeCurso(null)
            setInfoDtVenc(null)
            setInfoCelular(null)
            setInfoEmail(null)
            setInfoDiasAtraso(null)


            const checkObjectKeys = Object.keys(detalhesRegistro)[1]
            //console.log('DEV COM RENÃ - AVALIAR: ', detalhesRegistro[checkObjectKeys])
            //console.log('DEV COM RENÃ - AVALIAR apenas checkObjectKeys: ', checkObjectKeys)
            //console.log('DEV COM RENÃ - AVALIAR apenas checkObjectKeys[0]: ', checkObjectKeys[0])
    
            // Soma os valores de VALORITENSAPAGAR dentro do array associado à chave checkObjectKeys
            const valorTotal = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.VALORITENSAPAGAR
            }, 0)
    
            // Soma os valores de outras variáveis dentro do array associado à chave checkObjectKeys
            const valorFinalDaCobranca = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.VALORFINALDACOBRANCA
            }, 0)
    
            const multaCalc = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.MULTACALC
            }, 0)
    
            const jurosCalc = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.JUROSCALC
            }, 0)
    
            const valorTotalEncargo = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.VALORTOTALENCARGOPAGAMENTO
            }, 0)

            const valorEncargoBolsa = detalhesRegistro[checkObjectKeys].reduce((acumulador, item) => {
                return acumulador + item.ENCARGO_BOLSA
            }, 0)
            
            const consolidadoInfos = detalhesRegistro[checkObjectKeys]


            setInfoArrayDetalhesParcela(consolidadoInfos)
            setInfoValorFinal(valorTotal)
            setInfoValorReal(valorFinalDaCobranca)
            setInfoValorMulta(multaCalc)
            setInfoValorJuros(jurosCalc)
            setInfoValorEncargo(valorTotalEncargo)
            setInfoValorEncargoBolsa(valorEncargoBolsa)

            const infoCobrancaReturn = detalhesRegistro[checkObjectKeys][0].COBRANCA
            setInfoCobranca(infoCobrancaReturn)
            const infoCursoReturn = detalhesRegistro[checkObjectKeys][0].NOME_CURSO
            setInfoNomeCurso(infoCursoReturn)
            const infoDtVencReturn = detalhesRegistro[checkObjectKeys][0].DATA_DE_VENCIMENTO
            setInfoDtVenc(infoDtVencReturn)
            const infoCelReturn = detalhesRegistro[checkObjectKeys][0].CELULAR
            setInfoCelular(infoCelReturn)
            const infoEmailReturn = detalhesRegistro[checkObjectKeys][0].E_MAIL
            setInfoEmail(infoEmailReturn)
            const infoDiasAtrasoReturn = detalhesRegistro[checkObjectKeys][0].NUM_DIAS_ATRASO
            setInfoDiasAtraso(infoDiasAtrasoReturn)
            const infoCpfTitular = detalhesRegistro[checkObjectKeys][0].CPF_TITULAR
            const infoNomeTitular = detalhesRegistro[checkObjectKeys][0].TITULAR
            setInfoTitularSearchPage(`${infoNomeTitular} (${infoCpfTitular})`)
        }
        
      }, [detalhesRegistro])



    useEffect(() => {
    // Verifica se todas as três variáveis têm valores (não são nulas ou indefinidas)
    if (selectedJuros !== null && infoValorReal !== null && infoValorEncargo !== null) {
        // Chame a função handleCountPercent com as variáveis
        const totalValue = handleCountPercent(selectedJuros, infoValorReal, infoValorEncargo)
        // Faça o que você precisa fazer com totalValue aqui
        //console.log('Total Value, vai setar updateValueTotalNegociation:', totalValue)
        setUpdateValueTotalNegociation(totalValue)
    }
    }, [selectedJuros, infoValorReal, infoValorEncargo])

    const handleChange = (selectedOption) => {
        const newSelectedJuros = selectedOption === 0 ? '0.0' : selectedOption
        setSelectedJuros(newSelectedJuros)
    }

    function formatValue(valor) {
        if (valor !== null && typeof valor === 'number') {
            const valorFormatado = valor.toFixed(2)
            const valorEmReais = `R$ ${valorFormatado.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
            return valorEmReais
        } else {
            return valor // Ou outra mensagem de erro
        }
    }
    function formatMoeda(valor) {
        const formatoMoeda = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      
        return formatoMoeda.format(valor)
    }

    function formatDate(inputDate) {
        const [year, month, day] = inputDate.split('-')
        const formattedDate = `${day}/${month}/${year}`
        return formattedDate
    }
    
    const handleToggleDivs = () => {
        setDivInfosParcelasConsolidado(!divInfosParcelasConsolidado)
    }

    const optionsPayment = [
        { value: 'credit_card', label: 'Cartão de Crédito' },
        { value: 'bank_slip', label: 'Boleto' },
        { value: 'pix', label: 'Pix' },
    ]
      
      const handlePaymentSelect = (e) => {
        setSelectedPaymentValue(e.target.value)
    }
    
    const handlePaymentSelectEntry = (e) => {
        setSelectedPaymentValueEntry(e.target.value)
    }

    const handleVencimentoSelecionado = (e) => {
        const selectedDate = e.target.value
        setVencimentoSelecionado(selectedDate)
    }

    function calculateClass() {
        if (!loadingSearchRegistration) {
          return 'divSearchLyceumContentButton'
        } else {
          return 'divSearchLyceumContentButtonDisabled'
        }
    }
    
    function calculateClassSolicitarNegociacao() {
        if (!loadingSearchGet) {
          return 'divSearchLyceumContentButton'
        } else {
          return 'divSearchLyceumContentButtonDisabled'
        }
    }

    function calculateClassAcordo() {
        if (!loadingAcordo) {
          return 'divSearchLyceumContentButton'
        } else {
          return 'divSearchLyceumContentButtonDisabled'
        }
    }

    const resetButtonPesquisar = () => {
            

        setReturnSearchRegistration(null)
        setLoadingSearchRegistration(false)
        setContent(false)
        setContentSelected(false)
        setCpfSearchPage(null)
        setNameSearchPage(null)
        setRegistrationSearchPage(null)
        setConsultIdSearchPage(null)
        setSelectedDebtIndex(null)
        setArrayJuros([])
        setSelectedJuros(null)
        setSelectedIdInternal(null)
        setValorSemEncargos(null)
        setValorEncargos(null)
        setUpdateValueTotalNegociation(null)
        setReturnNegociation({})
        setReturnAcordoDiv(false)
        setSelectedInternalSimulationId(null)
        setSimulationId(null)
        setDueDay(null)
        setEmailNotify(null)
        setValorEncargoSemDesconto(null)
        setValorEncargoNegociado(null)
        setValorDescontoEncargo(null)
        setInputEntryValue(0.0)
        setVencimentoSelecionado(null)
        setEmailNotify(null)
        setSelectedPaymentValue(null)
        setSelectedPaymentValueEntry(null)

    }

    const resetButtonSolicitarNegociacao = () => {
        setLoadingSearchRegistration(false)
        setReturnNegociationDiv(false)
        setReturnNegociation({})
        setReturnAcordoDiv(false)
        setSelectedInternalSimulationId(null)
        setSimulationId(null)
        setDueDay(null)
        setEmailNotify(null)
        setVencimentoSelecionado(null)
        setEmailNotify(null)
        setSelectedPaymentValue(null)
    }

    const handleSearchRegistration = async () =>{
        if(!registerClient){
            alert('Você não informou uma matrícula, favor verificar. <3')
            return
        }
        resetButtonPesquisar()
        setLoadingSearchRegistration(true)
        try {
            const payments = await axios.get(`${process.env.REACT_APP_REQ}outstanding_debt?matricula=${registerClient}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            //console.log('DEV - Requisição pesquisa de débitos:', payments.data)
            await setReturnSearchRegistration(payments.data)
            await setCpfSearchPage(payments.data.cliente.CPF)
            await setNameSearchPage(payments.data.cliente.Nome)
            await setRegistrationSearchPage(payments.data.cliente.Matricula)
            await setConsultIdSearchPage(payments.data.consult_id)
            setContent(true)
            setContentSelected(true)
        } catch(error) {
            console.log('error.response', error.response.data.msg)
            alert(error.response.data.msg)
            if (error.response && error.response.status === 401) {
                navigate('/logout')
            }
        } finally{
            setLoadingSearchRegistration(false)
          }
    }

    const handleNegociation = async () => {
        resetButtonSolicitarNegociacao()
        if (!selectedJuros) {
            alert('Você não selecionou um valor referente a negociação dos encargos, favor verificar <3!')
            return
        }
        const floatSelected = parseFloat(selectedJuros)
        //console.log('PRÉ REQUISIÇÃO: idSearchPage', consultIdSearchPage, '- selectId', selectedIdInternal, ' / Juros selecionado', floatSelected, ' - Token', token, ' - entryValue: ', inputEntryValue)
        setLoadingSearchGet(true)
        
        const requestData = {
            consult_id: consultIdSearchPage,
            internal_consult_id: selectedIdInternal,
            fees_and_penalty_discount: floatSelected,
            debt_discount: 0.0,
        }
        if (inputEntryValue !== 0 && inputEntryValue !== null) {
            //console.log('Inserindo o valor de entrada na condição: inputEntryValue !== 0 && inputEntryValue !== null')
            requestData.entry_value = inputEntryValue
        }

        try {
            const negociation = await axios.post(
                `${process.env.REACT_APP_REQ}simulation`,
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
    
            if (negociation.status === 200) {
                console.log('DEV - Requisição solicita negociação:', negociation.data)
                //console.log('DEV - Requisição solicita negociação vencimentos_disponiveis:', negociation.data.vencimentos_disponiveis)
                setReturnNegociation(negociation.data)
                //setVencimentosDisponiveisArray(negociation.data.vencimentos_disponiveis)
                setSimulationId(negociation.data.simulation_id)
                setReturnNegociationDiv(true)
            }
        } catch (error) {
            console.error('Erro na requisição:', error)
            alert('Erro na requisição: ' + error.message)
            if (error.response && error.response.status === 401) {
                navigate('/logout')
            }
        } finally {
            setLoadingSearchGet(false)
        }
    }
    const handleReloadPage = () => {
        window.location.reload()
    }
    const handleAcordo = async () => {
        if(!vencimentoSelecionado){
            alert('Você não informou o dia de vencimento dos boletos referente a esta negociação, favor verificar. <3')
            return
        }
        if(!emailNotify){
            alert('Você não informou o e-mail para cadastro no portal de negociações do cliente, por favor verifique. <3')
            return
        }
        if(!selectedPaymentValue){
            alert('Você não informou método de pagamento, por favor verifique. <3')
            return
        }
        if ((inputEntryValue !== 0 && inputEntryValue !== null) && !selectedPaymentValueEntry) {
            alert('Você deve informar o método de pagamento para a entrada.')
            return
        }
        setLoadingAcordo(true)
        console.log('PRÉ REQUISIÇÃO - ACORDO: simulationId:', simulationId, '- internal_simulation_id:', selectedInternalSimulationId, ' / vencimentoSelecionado:', vencimentoSelecionado, ' do mês / notify_email:', emailNotify, ' / Token', token, ' / Método de pgto:', selectedPaymentValue)
        try {
            const acordo = await axios.post(
                `${process.env.REACT_APP_REQ}agreement`,
                {
                    simulation_id: simulationId,
                    internal_simulation_id: selectedInternalSimulationId,
                    due_day: vencimentoSelecionado,
                    notify_email: emailNotify,
                    payment_method: selectedPaymentValue,
                    entry_payment_method: inputEntryValue === 0 || inputEntryValue === null ? "pix" : selectedPaymentValueEntry
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
    
            if (acordo.status === 200) {
                console.log('DEV - Requisição solicita ACORDO 200:', acordo.data)
                setReturnAcordo(acordo.data)
                setReturnAcordoDiv(true)
            }
        } catch(error) {
            console.log('error.response', error.response.data.msg)
            alert(error.response.data.msg)
            if (error.response && error.response.status === 401) {
                navigate('/logout')
            }
        } finally{
            console.log('Finalizou solicitação de acordo!')
            setLoadingAcordo(false)
          }
    }

    const handleSelectRegistration = (index) => {
        if (selectedDebtIndex === index) {
            setSelectedDebtIndex(null)
            setReturnNegociationDiv(false)
            setReturnNegociation({})
            setSelectedJuros(null)
            setUpdateValueTotalNegociation(null)
            setValorSemEncargos(null)
            setValorEncargos(null)
            setSelectedInternalSimulationId(null)
            setDueDay(null)
            setEmailNotify(null)
            setReturnAcordoDiv(false)
            setReturnAcordo({})
            
            setInfoCobranca(null)
            setInfoNomeCurso(null)
            setInfoDtVenc(null)
            setInfoValorReal(null)
            setInfoValorMulta(null)
            setInfoValorJuros(null)
            setInfoValorEncargo(null)
            setInfoValorEncargoBolsa(null)
            setInfoValorFinal(null)
            setInfoCelular(null)
            setInfoEmail(null)
            setInfoDiasAtraso(null)
            
            const selectedDebt = returnSearchRegistration.retorno[index]
            const juros = selectedDebt.DESCONTO.DESCONTO_ENCARGOS
            setArrayJuros(juros)
            const idSelected = selectedDebt.INTERNAL_CONSULT_ID
            setSelectedIdInternal(idSelected)
            const valorSemEncargosTemp = selectedDebt.VALORFINALDACOBRANCA
            setValorSemEncargos(valorSemEncargosTemp)
            const valorEncargosTemp = selectedDebt.VALORTOTALENCARGOPAGAMENTO
            setValorEncargos(valorEncargosTemp)
            setDetalhesRegistro(selectedDebt)
        } else {
            setSelectedDebtIndex(index)
            setReturnNegociationDiv(false)
            setReturnNegociation({})
            setSelectedJuros(null)
            setValorSemEncargos(null)
            setValorEncargos(null)
            setUpdateValueTotalNegociation(null)
            setSelectedInternalSimulationId(null)
            setDueDay(null)
            setEmailNotify(null)
            setReturnAcordoDiv(false)
            setReturnAcordo({})

            
            setInfoCobranca(null)
            setInfoNomeCurso(null)
            setInfoDtVenc(null)
            setInfoValorReal(null)
            setInfoValorMulta(null)
            setInfoValorJuros(null)
            setInfoValorEncargo(null)
            setInfoValorEncargoBolsa(null)
            setInfoValorFinal(null)
            setInfoCelular(null)
            setInfoEmail(null)
            setInfoDiasAtraso(null)
            
            const selectedDebt = returnSearchRegistration.retorno[index]
            const juros = selectedDebt.DESCONTO.DESCONTO_ENCARGOS
            setArrayJuros(juros)
            const idSelected = selectedDebt.INTERNAL_CONSULT_ID
            setSelectedIdInternal(idSelected)
            const valorSemEncargosTemp = selectedDebt.VALORFINALDACOBRANCA
            setValorSemEncargos(valorSemEncargosTemp)
            const valorEncargosTemp = selectedDebt.VALORTOTALENCARGOPAGAMENTO
            setValorEncargos(valorEncargosTemp)
            setDetalhesRegistro(selectedDebt)
        }
    }
    
    const handleSelectNegociation = (internalSimulationId) => {
        if (selectedInternalSimulationId === internalSimulationId) {
            // Se o mesmo valor for selecionado novamente, desmarque-o
            setSelectedInternalSimulationId(null);
        } else {
            setSelectedInternalSimulationId(internalSimulationId)
        }
    }

    const handleCountPercent = (percentDiscount, valueNoCharge, chargeValue) => {
        const bolsaEncargo = infoValorEncargoBolsa
        if(percentDiscount === 0){
            const finallyValue = valueNoCharge + chargeValue + bolsaEncargo
            return finallyValue
        }else{
            const percentValue = chargeValue * percentDiscount // DEFININDO DESCONTO ENCARGO * PERCENT 0,2 retorno 34,34
            setValorEncargoSemDesconto(chargeValue)
            setValorDescontoEncargo(percentValue)

            const valueDiscountPercentApplied = chargeValue - percentValue
            setValorEncargoNegociado(valueDiscountPercentApplied)


            const finallyValue = valueNoCharge + valueDiscountPercentApplied + bolsaEncargo
            return finallyValue
        }
    }

    function calcularOutrosValor(item) {
        const valorTotal = infoValorEncargo
        const juros = infoValorJuros
        const multa = infoValorMulta
    
        // Calcula o valor resultante da subtração
        const outros = valorTotal - juros - multa
    
        return outros
    }

    function formatToPercentage(value) {
        if (value !== null && typeof value === 'number') {
            // Converte o valor para porcentagem
            return `${(value * 100).toFixed(1)}%`
        } else {
            // Trate o caso em que value é nulo ou não é um número
            return value // ou qualquer outra mensagem de erro
        }
    }
    
    const options = arrayJuros.map((option, index) => (
        <div
            key={index}
            className={`divSearchLyceumJurosOption ${selectedJuros === option || (selectedJuros === '0.0' && option === 0) ? 'divSearchLyceumJurosSelect' : ''}`}
            onClick={() => handleChange(option)}
        >
            {formatToPercentage(option)}
        </div>
    ))

    return (
        <div className='divSearchLyceum'>
            <MenuGeneral textColor="#000000" backgroundColor="#FFFFFF" />
            <div className='divSearchLyceumContent'>
                <TitlePage title={'PESQUISA POR MATRÍCULA'} subTitle={'Informe a matrícula abaixo:'} />
                
                <div className='divSearchLyceumContentSub'>
                    <b><Text className={'divSearchLyceumContentTitle'} text={'Pesquise uma matrícula:'} /></b>
                    <Input className={'divSearchLyceumContentInput'} type={'text'} placeholder={'Informe uma matrícula'} value={registerClient} onChange={(e) => setRegisterClient(e.target.value)} />
                    <Button className={calculateClass()} text={'Pesquisar'} onClick={handleSearchRegistration} disabled={loadingSearchRegistration}/>
                    {loadingSearchRegistration &&
                        <div className='divLoadingSearchRegistration'>
                            <LoadingImageRotate loadingImage={loadingImageFromPage} altLoadingImage={'Icone de loading'} width={'80px'} text={'P E S Q U I S A N D O'} />
                        </div>}
                    {content &&
                        <div className='divSearchLyceumContentReturn'>
                            <b><Text className={'divSearchLyceumContentTitle'} text={'Dados do ALUNO:'} /></b>
                            <ClientData className={'divSearchLyceumContentReturnClientData'} classNameInternal={'divSearchLyceumRow'} registrationClient={registrationSearchPage} nameClient={nameSearchPage} cpfClient={cpfSearchPage} />
                            <b><Text className={'divSearchLyceumContentTitle'} text={'DÉBITOS(Clique sobre a cobrança para ver detalhes):'} /></b>

                            {contentSelected && <div className='divSearchLyceumContentReturnIcons'>
                            
                            {returnSearchRegistration.retorno.map((item, index) => (
                                <div className='divSearchLyceumContentReturnMaster'>
                                    <div key={index}  className={`divSearchLyceumContentReturnRegister ${selectedDebtIndex === index ? 'selected' : ''}`}  onClick={() => handleSelectRegistration(index)}  >
                                        <Img className={'divSearchLyceumContentReturnRegisterImg'} imageUrl={iconRegister} alt={'Ícone Registro Dívida'} />
                                        <ResumeData className={'divSearchLyceumContentReturnDataResumeItem'} debtRegister={cobrancaReturnSelected} debtValue={`${Object.keys(item)[1]} ( ${item.TIPO} )`} />
                                        
                                    </div>
                                    {contentSelected && selectedDebtIndex === index && (
                                            <div className='divSearchLyceumContentReturnIcons'>
                                                <div className='divSearchLyceumContentReturnIconsContent'>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconSchool} alt={'Ícone Instituicao'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'CREDOR:'} />
                                                            </div>
                                                            <div>
                                                                <b><div className={'divSearchLyceumInfoReturnRegister'}>UNIFENAS {infoArrayDetalhesParcela ? null : infoCobranca} / {infoNomeCurso} </div></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconCalendar} alt={'Ícone Calendário'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegisterText'} text={'VENCIMENTO:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegisterText'} text={infoDtVenc} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconPrice} alt={'Ícone Preço'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'VALOR SEM ENCARGOS:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={formatValue(infoValorReal)} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconPrice} alt={'Ícone Preço'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'ENCARGOS:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={`VALOR TOTAL: ${formatValue(infoValorEncargo)} - JUROS (${formatValue(infoValorJuros)}) / MULTA (${formatValue(infoValorMulta)})`} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconPrice} alt={'Ícone Preço'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'ENCARGOS(BOLSA):'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={`VALOR TOTAL: ${formatValue(infoValorEncargoBolsa)}`} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconPrice} alt={'Ícone Preço'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'VALOR TOTAL EM ABERTO:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={formatValue(infoValorFinal)} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconCalendar} alt={'Ícone Calendário'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegisterText'} text={'DIAS EM ATRASO:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegisterText'} text={infoDiasAtraso} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconPhone} alt={'Ícone Celular'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'CELULAR:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={infoCelular} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconMail} alt={'Ícone E-mail'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'E-MAIL:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={infoEmail} /></b>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    

                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconId} alt={'Ícone ID'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'TITULAR DA DÍVIDA:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={infoTitularSearchPage} /></b>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='divSearchLyceumContentReturnIconContent'>
                                                        <Icon className={'divSearchLyceumContentReturnIconImg'} imageUrl={iconId} alt={'Ícone ID'} />
                                                        <div className='divSearchLyceumInfoReturnRegisterContent'>
                                                            <div>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'Internal ID:'} />
                                                            </div>
                                                            <div>
                                                                <b><Text className={'divSearchLyceumInfoReturnRegister'} text={item.INTERNAL_CONSULT_ID} /></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {infoArrayDetalhesParcela && infoArrayDetalhesParcela.length >= 1 && (
                                                        <div className='divSearchLyceumColumnNotGap'>
                                                            <div className='divSearchLyceumDetailsClick' onClick={handleToggleDivs}>
                                                                <b>{divInfosParcelasConsolidado ? 'Ocultar detalhes' : 'Ver detalhes da COBRANÇA'}</b>
                                                            </div>
                                                            
                                                            {divInfosParcelasConsolidado && (
                                                                <>
                                                                    {infoArrayDetalhesParcela.map((item, index) => (
                                                                    <div className='divSearchLyceumColumnNotGapEdit' key={index}>
                                                                        <div>Curso: <b>{item.NOME_CURSO}</b></div>
                                                                        <div>Cobrança: <b>{item.COBRANCA}</b></div>
                                                                        <div>Vencimento: <b>{item.DATA_DE_VENCIMENTO}</b></div>
                                                                        <div>Valor SEM ENCARGOS: <b>{formatValue(item.VALORFINALDACOBRANCA)}</b></div>
                                                                        <div>Valor ENCARGOS: <b>{formatValue(item.VALORTOTALENCARGOPAGAMENTO)}</b></div>
                                                                        <div>Valor FINAL(+ ENCARGOS): <b>{formatValue(item.VALORITENSAPAGAR)}</b></div>
                                                                        
                                                                    </div>
                                                                    ))}
                                                            </>
                                                            )}
                                                        </div>
                                                    ) 
                                                        
                                                    }


                                                    <b><Text className={'divSearchLyceumContentTitleBig'} text={'N E G O C I A Ç Ã O :'} /></b>
                                                    
                                                    <div className='divSearchLyceumNegociation'>

                                                        <div className='divSearchLyceumNegociationColum'>
                                                            <Text className={'divSearchLyceumContentTitle20px'} text={'Selecione o desconto referente aos ENCARGOS(JUROS + MULTA):'} />
                                                            <div className='divSearchLyceumNegociationRow'>{options}</div>
                                                        </div>
                                                        {updateValueTotalNegociation &&
                                                            <div>
                                                                <Text className={'divSearchLyceumContentTitle20px'} text={`ENCARGOS: ${formatValue(infoValorEncargo)}`} />
                                                                <Text className={'divSearchLyceumContentTitle20px'} text={`DESCONTO(ENCARGOS): ${formatValue(valorDescontoEncargo)}`} />
                                                                <Text className={'divSearchLyceumContentTitle20px'} text={`ENCARGOS - NEGOCIADO: ${formatValue(valorEncargoNegociado)}`} />
                                                                <Text className={'divSearchLyceumContentTitle20px'} text={'VALOR FINAL COM ENCARGOS NEGOCIADOS: '} /> 
                                                                <b><Text className={'divSearchLyceumContentTitleBig'} text={formatValue(updateValueTotalNegociation)} /></b>
                                                            </div>
                                                        }
                                                        <div className='divSearchLyceumNegociationColum'>
                                                            <Text className={'divSearchLyceumContentTitle20px'} text={'Informe um valor de entrada(R$):'} />
                                                            <Input className={'divSearchLyceumContentInput55'} type={'number'} placeholder={'Informe um valor de entrada(R$)'} value={inputEntryValue} onChange={(e) => setInputEntryValue(e.target.value)} />
                                                        </div>
                                                        {divPaymentEntry && (
                                                            <div className='divSearchLyceumNegociationColum'>
                                                                <Text className={'divSearchLyceumContentTitle20pxMaxWid'} text={'REFERE A ENTRADA - Informe o método de pagamento:'} />
                                                                <select className='divSearchLyceumContentSelect' id="paymentMethod" value={selectedPaymentValueEntry} onChange={handlePaymentSelectEntry}>
                                                                    <option value="">ENTRADA - Selecione o método de pagamento</option>
                                                                    {optionsPayment.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                        {option.label} 
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                        <Button className={calculateClassSolicitarNegociacao()} disabled={loadingSearchGet} text={'Solicitar negociação'} onClick={handleNegociation} />
                                                        {loadingSearchGet && 
                                                            <div className='divLoadingSearchRegistration'>
                                                                <LoadingImageRotate loadingImage={loadingImageFromPage} altLoadingImage={'Icone de loading'} width={'80px'} text={'GERANDO N E G O C I A Ç Õ E S'} />
                                                            </div>
                                                        }
                                                        {returnNegociationDiv && (
                                                            <div className='divSearchLyceumColumn'>
                                                              <div className='divSearchLyceumColumn'>
                                                                <Text className={'divSearchLyceumContentTitleBig'} text={'S I M U L A Ç Õ E S (clique sobre a opção para escolher):'} />
                                                                {returnNegociation.resultado.simulacoes.map((simulacao, index) => {
                                                                    // Calcular valor da parcela
                                                                    const valorParcela = (simulacao.valor_final - inputEntryValue) / simulacao.numero_parcelas

                                                                    return (
                                                                    <div key={index} className={`divSearchLyceumSimulations ${selectedInternalSimulationId === simulacao.internal_simulation_id ? 'divSearchLyceumSimulationsSelected' : ''}`} onClick={() => handleSelectNegociation(simulacao.internal_simulation_id)}>
                                                                        

                                                                        <div className='divSearchLyceumRow'>
                                                                                <b><Text className={'divSearchLyceumContentTitle20px'} text={simulacao.tipo.toUpperCase()} /></b>
                                                                        </div>
                                                                        {simulacao.numero_parcelas > 0 && (
                                                                            <div className='divSearchLyceumRowNotCenter'>
                                                                                <div className='divSearchLyceumRow'>
                                                                                    <div>ENTRADA:</div> 
                                                                                    <b><div className={'divSearchLyceumContentTitle20px'}>{formatMoeda(inputEntryValue)}</div></b>
                                                                                    <div><b>+</b></div> 
                                                                                </div>
                                                                                <div className='divSearchLyceumRowNotGap'>
                                                                                    <b><Text className={'divSearchLyceumContentTitle20px'} text={simulacao.numero_parcelas} /></b>
                                                                                    <div>x de</div>
                                                                                </div>
                                                                                <div className='divSearchLyceumRow'>
                                                                                    <b><div className={'divSearchLyceumContentTitle20px'} >{isNaN(valorParcela) ? '' : `${formatValue(valorParcela)}`}</div></b>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        
                                                                        <div className='divSearchLyceumRow'>
                                                                            <div className='divSearchLyceumRow'>
                                                                                <div>VALOR FINAL:</div> 
                                                                                <b><div className={'divSearchLyceumContentTitle20px'} >{formatValue(simulacao.valor_final)}</div></b>
                                                                                {simulacao.tabela_price &&
                                                                                    <div>
                                                                                        <Img className={'divSearchLyceumPriceIcon'} imageUrl={iconTabelaPrice} alt={'Imagem Tabela Price'} />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    )
                                                                })}
                                                                <Text className={'divSearchLyceumContentTitle20pxMaxWid'} text={'Selecione o dia de vencimento da fatura:'} />
                                                                {returnNegociation.vencimentos_disponiveis && 
                                                                    <select className='divSearchLyceumContentSelect' id="selectVencimentos" value={vencimentoSelecionado} onChange={handleVencimentoSelecionado} >
                                                                        <option value="">Selecione o dia de vencimento da fatura:</option>
                                                                        {returnNegociation.vencimentos_disponiveis.map((vencimento, index) => (
                                                                            <option key={index} value={vencimento}>
                                                                            {formatDate(vencimento)}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                }
                                                                <Text className={'divSearchLyceumContentTitle20pxMaxWid'} text={'Informe e-mail para cadastro no portal de negociações:'} />
                                                                <Input className={'divSearchLyceumContentInput55'} type={'email'} placeholder={'Informe um e-mail'} value={emailNotify} onChange={(e) => setEmailNotify(e.target.value)} />
                                                                <Text className={'divSearchLyceumContentTitle20pxMaxWid'} text={'Informe o método de pagamento referente ao acordo:'} />
                                                                <select className='divSearchLyceumContentSelect' id="paymentMethod" value={selectedPaymentValue} onChange={handlePaymentSelect}>
                                                                    <option value="">Selecione o método de pagamento</option>
                                                                    {optionsPayment.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                    ))}
                                                                </select>
                                                                
                                                                

                                                                


                                                                <Button className={calculateClassAcordo()} text={'GERAR ACORDO'} onClick={handleAcordo} disabled={loadingAcordo} />
                                                                {loadingAcordo && 
                                                                    <div className='divLoadingSearchRegistration'>
                                                                        <LoadingImageRotate loadingImage={loadingImageFromPage} altLoadingImage={'Icone de loading'} width={'80px'} text={'GERANDO A C O R D O'} />
                                                                    </div>
                                                                }
                                                                {returnAcordoDiv && <div className='divSearchLyceumColumn'>
                                                                    <b><Text className={'divSearchLyceumContentTitleBig'} text={'ACORDO FINALIZADO :'} /></b>
                                                                    {returnAcordo.faturas.slice().sort((a, b) => a.numero_parcela - b.numero_parcela).map((fatura, index) => (
                                                                        <div key={index} className='divSearchLyceumColumnAcordo'>
                                                                        <div><b>FATURA: {fatura.numero_parcela} - VALOR: {formatValue(fatura.valor)} </b>| VENCIMENTO: {formatDate(fatura.data_vencimento)}</div>
                                                                        <div>
                                                                            {fatura.link_pagamento ? (
                                                                            <a href={fatura.link_pagamento} target="_blank" className='divSearchLyceumContentButtonLittle'>
                                                                                Clique aqui para acessar a fatura
                                                                            </a>
                                                                            ) : (
                                                                            <div>Será gerada de acordo com o vencimento</div>
                                                                            )}
                                                                        </div>
                                                                        </div>
                                                                    ))}
                                                                    </div>}
                                                              </div>
                                                            </div>
                                                          )}
                                                          
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        )}
                                </div>
                                
                            ))}


                               
                                
                                
                                
                            </div>}
                        </div>
                    }
                    <div className='divSearchLyceumDivLogo'>
                        <Img className={'divSearchLyceumLogo'} imageUrl={logoWhite} alt={'Logo Young'} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SearchLyceum