import './IconMenu.css'

const IconMenu = ({imageUrl, alt, title, onClick}) => {

    return (
        <div className='menuItem' onClick={onClick}>
            <div>
                <img className='menuItemImg' src={imageUrl} alt={alt} />
            </div>
            <div className='menuItemText'>
                <b>{title}</b>
            </div>
        </div>
    )
}
export default IconMenu