import './Icon.css'

const Icon = ({className, imageUrl, alt}) => {

    return (
        <div className='divIconBasic'>
            <img className={className}
            src={imageUrl}
            alt={alt}
            />
        </div>
    )
}
export default Icon