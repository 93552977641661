import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

const LogoutExternalPage = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    // Executar o logout quando o componente for montado
    logout(true)
    navigate('/external/login')
  }, [logout, navigate])

  return (
    <div>
      <h2>Fazendo logout...</h2>
    </div>
  )
}

export default LogoutExternalPage
