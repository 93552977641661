// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.loading_rotate{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.loading_rotate_img{
    animation: rotate 3s linear infinite;
    margin:16px;
}

`, "",{"version":3,"sources":["webpack://./src/components/LoadingImageRotate/loading.css"],"names":[],"mappings":";AACA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,oCAAoC;IACpC,WAAW;AACf","sourcesContent":["\n@keyframes rotate {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n\n.loading_rotate{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 30px;\n}\n.loading_rotate_img{\n    animation: rotate 3s linear infinite;\n    margin:16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
