
import axios from 'axios'
import './loginExternalPage.css'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { useState } from 'react'
import iconPerson from '../../icons/person.png'
import iconPsw from '../../icons/psw.png'
import loadingImageFromPage from '../../img/loading_branco.png'
import Img from '../../components/Img'
import imgLogoWhite from '../../img/young_branco.png'
import { useAuth } from '../../context/AuthContext'
import LoadingImageRotate from '../../components/LoadingImageRotate'
import { useNavigate } from 'react-router-dom'

const LoginExternalPage = () => {
    
    const navigate = useNavigate()
    const { login, saveName } = useAuth()
    const [emailExterno, setEmailExterno] = useState(null)
    const [senhaExterno, setSenhaExterno] = useState(null)
    const [buttonExternoContinuar, setButtonExternoContinuar] = useState(true)
    const [divSenha, setDivSenha] = useState(false)
    const [loadingLoginExterno, setLoadingLoginExterno] =useState(false)



function calcClassBotaoContinuar() {
    if (!emailExterno) {
        return 'divLoginExternalButtonDisabled'
    } else {
        return 'divLoginExternalButton'
    }
}



function calcClassBotaoLoginExternal() {
    if (!emailExterno || !senhaExterno) {
        return 'divLoginExternalButtonDisabled'
    } else {
        return 'divLoginExternalButton'
    }
}

const funcaoBotaoContinuar = async () => {
    setButtonExternoContinuar(false)
    setDivSenha(true)
}

const funcaoBotaoLogin = async () => {
    if(!emailExterno){
        alert('Você não informou o e-mail, verifica por favor. <3')
        return
    }
    if(!senhaExterno){
        alert('Você não informou a senha, pode verificar? Por favor. <3')
        return
    }
    setLoadingLoginExterno(true)
    console.log('Itens requisição pré requisição login externo:', emailExterno, ' e senha: ', senhaExterno)
    try {
        console.log('DEV - Iniciando requisição de LOGIN Externo! ♥')
        const response = await axios.post(`${process.env.REACT_APP_REQ}clientlogin/`, {
            email: emailExterno,
            password: senhaExterno,
        })
        if (response && response.data && response.data.Bearer) { 
            console.log('DEV - ♥ Deu certo, response.data:', response.data)
            login(response.data.Bearer, true)
            saveName(response.data.name)
            navigate('/external/content')
        }
      } catch (error) {
        console.log('error.response', error.response)
        alert(error.response.data.msg)
    }finally{
        setLoadingLoginExterno(false)
      }
    
}

return(
    <div className='divLoginExternal'>
        <Img className={'divLoginExternalLogo'}  imageUrl={imgLogoWhite} alt={'Logo'} />
        <div className='divLoginExternalTitle'>
            <Icon className={'divLoginExternalIconImg'} imageUrl={iconPerson} alt={'Ícone pessoa'}/>
            <Text className={'divLoginExternalText'} text={'Informe seu e-mail:'} />
        </div>
        <Input className={'divLoginExternalInput'} type={'text'} placeholder={'Informe seu login e-mail'} value={emailExterno} onChange={(e) => setEmailExterno(e.target.value)} />
        {buttonExternoContinuar && <Button onClick={funcaoBotaoContinuar} text={'Continuar'} className={calcClassBotaoContinuar()} />}
        {divSenha && 
            <div className='divLoginExternalColumn'>
                <div className='divLoginExternalTitle'>
                    <Icon className={'divLoginExternalIconImg'} imageUrl={iconPsw} alt={'Ícone senha'}/>
                    <Text className={'divLoginExternalText'} text={'Informe sua senha:'} />
                </div>
                <Input className={'divLoginExternalInput'} type={'password'} placeholder={'Informe sua senha'} value={senhaExterno} onChange={(e) => setSenhaExterno(e.target.value)} />
                <Button onClick={funcaoBotaoLogin} text={'Login'} className={calcClassBotaoLoginExternal()} />
                {loadingLoginExterno && 
                    <div className='divLoginExternalLoading'>
                        <LoadingImageRotate loadingImage={loadingImageFromPage} altLoadingImage={'Icone de loading'} width={'80px'} text={'L O G I N . . .'} />
                    </div>
                }
            </div>
        }
    </div>
)
}
export default LoginExternalPage