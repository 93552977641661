import './TitlePage.css'

const TitlePage = ({title, subTitle}) => {
    return (
        <div className='titlePageDiv'>
            <div className='titlePageDivTop'><b>{title}</b></div>
            <div className='titlePageDivBot'>{subTitle}</div>
        </div>
        )
    
}

export default TitlePage