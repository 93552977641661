// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resumeDataDebtRegister{
    font-size: 24px;
    width: 30%;
    display: flex;
    justify-content: center;
}

.resumeDataDebtValue{
    font-size: 24px;
    width: 70%;
    display: flex;
    justify-content: center;
}


@media (max-width: 768px) {
    .resumeDataDebtRegister{
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .resumeDataDebtValue{
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
       
}`, "",{"version":3,"sources":["webpack://./src/components/ResumeData/ResumeData.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI;QACI,eAAe;QACf,WAAW;QACX,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,eAAe;QACf,WAAW;QACX,aAAa;QACb,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".resumeDataDebtRegister{\n    font-size: 24px;\n    width: 30%;\n    display: flex;\n    justify-content: center;\n}\n\n.resumeDataDebtValue{\n    font-size: 24px;\n    width: 70%;\n    display: flex;\n    justify-content: center;\n}\n\n\n@media (max-width: 768px) {\n    .resumeDataDebtRegister{\n        font-size: 16px;\n        width: 100%;\n        display: flex;\n        justify-content: center;\n    }\n    \n    .resumeDataDebtValue{\n        font-size: 16px;\n        width: 100%;\n        display: flex;\n        justify-content: center;\n    }\n       \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
