import './ResumeData.css'
import React from 'react'

const ResumeData = ({ onClick, className, debtRegister, debtValue, key }) => {
  return <div onClick={onClick} className={className} key={key}>
      <div className='resumeDataDebtRegister'><b>{debtRegister}</b></div>
      <div className='resumeDataDebtValue'>{debtValue}</div>
    </div>
}

export default ResumeData