import React from 'react'

const Text = ({className, text, onClick}) => {
    return (
        <div className={className} onClick={onClick}>
            {text}
        </div>
        )
    
}

export default Text