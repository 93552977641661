import './ClientData.css'
import React from 'react'

const ClientData = ({ className, registrationClient, nameClient, cpfClient, classNameInternal }) => {
  return <div className={className}>
      <div className={classNameInternal}>
        <div>MATRÍCULA:</div>
        <div><b>{registrationClient}</b></div>
      </div>
      <div className={classNameInternal}>
        <div>NOME:</div>
        <div><b>{nameClient}</b></div>
      </div>
      <div className={classNameInternal}>
        <div>CPF:</div>
        <div><b>{cpfClient}</b></div>
      </div>
    </div>
}

export default ClientData