import './loading.css'
import React from 'react'

const LoadingImageRotate = ({ loadingImage, altLoadingImage, width, text }) => {
  return <div className='loading_rotate'>
      <div><img className='loading_rotate_img' src={loadingImage} alt={altLoadingImage} width={width} /></div>
      <div><b>{text}</b></div>
    </div>
}

export default LoadingImageRotate


