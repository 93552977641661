import React, { createContext, useContext, useState } from 'react'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  
  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || null
  })

  const [tokenExternal, setTokenExternal] = useState(() => {
    return localStorage.getItem('tokenExternal') || null
  })

  const [nameExternal, setNameExternal] = useState(localStorage.getItem('nameUserExternal') || null)

  const saveName = (nameUser) => {
    localStorage.setItem('nameUserExternal', nameUser)
    setNameExternal(nameUser)
  }

  const login = (newToken, isExternal = false) => {
    if (isExternal) {
      // Lógica para autenticação externa
      localStorage.setItem('tokenExternal', newToken)
      setTokenExternal(newToken)
    } else {
      // Lógica para autenticação interna
      localStorage.setItem('token', newToken)
      setToken(newToken)
    }
  }

  const logout = (isExternal = false) => {
    if (isExternal) {
      // Lógica para logout externo
      localStorage.removeItem('tokenExternal')
      localStorage.removeItem('nameUserExternal')
    } else {
      // Lógica para logout interno
      localStorage.removeItem('token')
      setToken(null)
    }
  }

  return (
    <AuthContext.Provider value={{ token, tokenExternal, nameExternal, login, logout, saveName }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}