import React from 'react'
import './inputbrancocinzagrande.css'

const Input = ({ className, type, placeholder, value, onChange }) => {
  if (type === 'textarea') {
    return (
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
      />
    )
  }

  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
    />
  )
}

export default Input