// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px 0;
    gap:8px;
    cursor: pointer;
    text-decoration: none;
}
.menuItemImg{
    width: 40px;
}
.menuItemText{
    font-size: 10px;
    text-decoration: none;
}


@media (max-width: 768px) {
    .menuItem{
        padding: 0 10px 0 10px;
    }
    .menuItemImg{
        width: 30px;
    }
    .menuItemText{
        font-size: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/IconMenu/IconMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,OAAO;IACP,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,qBAAqB;AACzB;;;AAGA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".menuItem{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 0 10px 0;\n    gap:8px;\n    cursor: pointer;\n    text-decoration: none;\n}\n.menuItemImg{\n    width: 40px;\n}\n.menuItemText{\n    font-size: 10px;\n    text-decoration: none;\n}\n\n\n@media (max-width: 768px) {\n    .menuItem{\n        padding: 0 10px 0 10px;\n    }\n    .menuItemImg{\n        width: 30px;\n    }\n    .menuItemText{\n        font-size: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
