import React from 'react'

const Img = ({className, imageUrl, alt }) => {
  return (
    <img className={className}
      src={imageUrl}
      alt={alt}
    />
  )
}

export default Img