import './App.css'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginLyceum from './pages/LoginLyceum'
import SearchLyceum from './pages/SearchLyceum'
import { AuthProvider } from './context/AuthContext' // Importe o contexto de autenticação
import LogoutPage from './pages/LogoutPage'
import LoginExternalPage from './pages/LoginExternalPage'
import ContentExternalPage from './pages/ContentExternalPage'
import LogoutExternalPage from './pages/LogoutExternalPage'
import PerformancePage from './pages/PerformancePage'

function App() {
  return (
    <AuthProvider>
      <Router>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300&display=swap')
        </style>
        <Routes>
          {/* Rotas da aplicação interna */}
          <Route path="/" element={<LoginLyceum />} />
          <Route path="/search" element={<SearchLyceum />} />
          <Route path="/performance" element={<PerformancePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          {/* Rotas da aplicação externa */}
          <Route path="/external/login" element={<LoginExternalPage />} />
          <Route path="/external/content" element={<ContentExternalPage />} />
          <Route path="/external/logout" element={<LogoutExternalPage />} />
        </Routes>
      </Router>
    </AuthProvider>
    
  )
}

export default App