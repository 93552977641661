import './contentExternalPage.css'
import { useAuth } from '../../context/AuthContext'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import iconPrice from '../../icons/price.png'
import { useState, useEffect } from 'react'
import IconMenu from '../../components/IconMenu'
import exit from '../../icons/exit.png'
import blackHeart from '../../img/cora_preto.png'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import iconCalendar from '../../icons/calendar.png'
import iconX from '../../icons/x.png'
import iconParcela from '../../icons/payment.png'
import iconStatus from '../../icons/status.png'
import iconPayment2 from '../../icons/payment2.png'
import iconRegister from '../../icons/register.png'
import logoWhite from '../../img/young_branco.png'
import Img from '../../components/Img'

const ContentExternalPage = () => {
    
    const { tokenExternal, nameExternal } = useAuth()
    const [returnClientData, setReturnClientData] = useState({})
    const [returnClientDataDiv, setReturnClientDataDiv] = useState(false)
    const [errorRequisicaoDiv, setErrorRequisicaoDiv] = useState(false)
    const [expandedId, setExpandedId] = useState(null);
    const navigate = useNavigate()
    
    
    if (!tokenExternal) {
        return <Navigate to="/external/login" />
    }


    useEffect(() => {
        console.log('DEV - nameExternal useEffect:', nameExternal)
    }, [nameExternal])

    useEffect(() => {
        console.log('DEV - Token External useEffect:', tokenExternal)
    }, [tokenExternal])

    useEffect(() => {
        console.log('DEV - returnClientData useEffect:', returnClientData)
    }, [returnClientData])

    useEffect(() => {
        if (tokenExternal) {
            axios.get(`${process.env.REACT_APP_REQ}clientdata/`, {
                headers: {
                    Authorization: `Bearer ${tokenExternal}`
                }
            })
            .then(response => {
                setReturnClientDataDiv(true)
                setReturnClientData(response.data)
                setErrorRequisicaoDiv(false)
            })
            .catch(error => {
                console.error('DEV - Erro na solicitação GET para clientdata:', error)
                setReturnClientDataDiv(false)
                setReturnClientData({})
                setErrorRequisicaoDiv(true)
                if (error.response && error.response.status === 401) {
                    navigate('/external/logout')
                }
                navigate('/external/login')
            })
        }
    }, [tokenExternal])
    
    function formatDate(inputDate) {
        const [year, month, day] = inputDate.split('-')
        const formattedDate = `${day}/${month}/${year}`
        return formattedDate
    }
    function formatValue(valor) {
        if (valor !== null && typeof valor === 'number') {
            const valorFormatado = valor.toFixed(2)
            const valorEmReais = `R$ ${valorFormatado.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
            return valorEmReais
        } else {
            return valor
        }
    }

    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id)
    }

    return(
        <div>
            {returnClientDataDiv && (
                <div className='divExternalPage'>
                    <div className='divExternalPageMenu'>
                        <IconMenu imageUrl={blackHeart} title={'YOUNG'} />
                        <Link to='/external/logout' className='textDecorationNone'><IconMenu imageUrl={exit} title={'LOGOUT'} /></Link>
                    </div>
                    <div className='divExternalPageContent'>
                        <div className='divExternalPageContentTitle'>
                                <div className='divExternalPageTextMedium'><b>Olá, </b></div>
                                <div className='divExternalPageTextBig' onClick={() => toggleExpand('greeting')}>
                                    <b>{nameExternal ? nameExternal.toUpperCase() : 'Nome não disponível'}!</b>
                                </div>
                        </div>
                        <div className='divExternalPageTextMedium'><b>Abaixo se encontram os acordos negociados com você!</b></div>
                        {returnClientData.acordos_cliente.reduce((acordosPorId, acordo) => {
                            if (!acordosPorId[acordo.id_acordo]) {
                                acordosPorId[acordo.id_acordo] = []
                            }
                            acordosPorId[acordo.id_acordo].push(acordo)
                            return acordosPorId;
                        }, []).map((acordos, id_acordo) => (
                            <div key={id_acordo}>
                                <div className='divExternalPageFirst'>
                                    <Icon className={'divExternalPageReturnIconImgBig'} imageUrl={iconPayment2} alt={'Ícone forma de pagamento'} />
                                    <div>
                                        <b>
                                            <div onClick={() => toggleExpand(id_acordo)}>
                                                ACORDO ID:{id_acordo}
                                            </div>
                                        </b>
                                    </div>
                                </div>
                                {expandedId === id_acordo && (
                                    <div>
                                        {acordos.slice().sort((a, b) => a.numero_parcela - b.numero_parcela).map((acordo) => (
                                            <div key={acordo.numero_parcela} className='divExternalPageGeral'>
                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={'INFORMAÇÕES DETALHADAS:'} />
                                                <div className='divExternalPageIconContent'>
                                                    <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconParcela} alt={'Ícone parcela'} />
                                                    <div className='divExternalPageReturnContent'>
                                                        <div>
                                                            <Text className={'divExternalPageReturnContentText'} text={'Nº PARCELA:'} />
                                                        </div>
                                                        <div>
                                                            <b>
                                                                <Text className={'divExternalPageReturnContentText'} text={acordo.numero_parcela} />
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='divExternalPageIconContent'>
                                                    <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconPayment2} alt={'Ícone forma de pagamento'} />
                                                    <div className='divExternalPageReturnContent'>
                                                        <div>
                                                            <Text className={'divSearchLyceumInfoReturnRegister'} text={'FORMA DE PAGAMENTO:'} />
                                                        </div>
                                                        <div>
                                                            <b>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={acordo.forma_pagamento} />
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='divExternalPageIconContent'>
                                                    <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconPrice} alt={'Ícone preço'} />
                                                    <div className='divExternalPageReturnContent'>
                                                        <div>
                                                            <Text className={'divSearchLyceumInfoReturnRegister'} text={'VALOR:'} />
                                                        </div>
                                                        <div>
                                                            <b>
                                                                <Text className={'divSearchLyceumInfoReturnRegister'} text={formatValue(acordo.valor)} />
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className='divExternalPageIconContent'>
                                                    <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconCalendar} alt={'Ícone Calendário'} />
                                                    <div className='divExternalPageReturnContent'>
                                                        <div>
                                                            <Text className={'divExternalPageReturnContentText'} text={'VENCIMENTO:'} />
                                                        </div>
                                                        <div>
                                                            <b>
                                                                <Text className={'divExternalPageReturnContentText'} text={formatDate(acordo.data_vencimento)} />
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='divExternalPageIconContent'>
                                                    <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconStatus} alt={'Ícone status'} />
                                                    <div className='divExternalPageReturnContent'>
                                                        <div>
                                                            <Text className={'divExternalPageReturnContentText'} text={'STATUS PAGAMENTO:'} />
                                                        </div>
                                                        <div>
                                                            <b>
                                                                <Text className={'divExternalPageReturnContentText'} text={acordo.pago ? 'Sim' : 'Não'} />
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                {acordo.link_fatura && (
                                                    <div className='divExternalPageIconContent'>
                                                        <Icon className={'divExternalPageReturnIconImg'} imageUrl={iconRegister} alt={'Ícone registro'} />
                                                        <div className='divExternalPageReturnContent'>
                                                            <div>
                                                                <a href={acordo.link_fatura} target="_blank">Link da Fatura</a>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    <Text className={'divExternalPageReturnContentText'} text={acordo.status_fatura === 'pending' ? 'Pendente' : acordo.status_fatura} />
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className='divSearchLyceumDivLogo'>
                            <Img className={'divSearchLyceumLogo'} imageUrl={logoWhite} alt={'Logo Young'} />
                        </div>
                    </div>
                </div>
            )}
            {errorRequisicaoDiv && <div>Error na requisição!</div>}
        </div>
    )
}

export default ContentExternalPage