import React, { useState } from 'react'
import './menu.css'
import { Link } from 'react-router-dom'
import search from '../../icons/search.png'
import exit from '../../icons/exit.png'
import imgPerformance from '../../img/img123.png'
import blackHeart from '../../img/cora_preto.png'
import IconMenu from '../IconMenu'

function MenuGeneral({ textColor, backgroundColor }) {

  return (
    <div className='div_menu_general' style={{ color: textColor, backgroundColor: backgroundColor }}>
          <img src={blackHeart} alt={'Logo young'} className='div_menu_generalImg'/>
          <Link to='/search' className='textDecorationNone'><IconMenu imageUrl={search} title={'PESQUISAR'} /></Link>
          <Link to='/performance' className='textDecorationNone'><IconMenu imageUrl={imgPerformance} title={'NEGOCIAÇÕES'} /></Link>
          <Link to='/logout' className='textDecorationNone'><IconMenu imageUrl={exit} title={'LOGOUT'} /></Link>
    </div>
  )
}

export default MenuGeneral
