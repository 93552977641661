// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titlePageDiv{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.titlePageDivTop{
    font-size: 40px;
}

.titlePageDivBot{
    font-size: 28px;
}


@media (max-width: 768px) {
    .titlePageDiv{
        gap: 8px;
    } 
    
    .titlePageDivTop{
        font-size: 24px;
    }

    .titlePageDivBot{
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TitlePage/TitlePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".titlePageDiv{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.titlePageDivTop{\n    font-size: 40px;\n}\n\n.titlePageDivBot{\n    font-size: 28px;\n}\n\n\n@media (max-width: 768px) {\n    .titlePageDiv{\n        gap: 8px;\n    } \n    \n    .titlePageDivTop{\n        font-size: 24px;\n    }\n\n    .titlePageDivBot{\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
