// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_menu_general{
    width: 100px;
    height: 100vh;
    position: fixed; 
    top: 0; 
    left: 0; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 36px;
}

.div_menu_generalImg{
    width: 40px;
}

.textDecorationNone{
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuGeneral/menu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".div_menu_general{\n    width: 100px;\n    height: 100vh;\n    position: fixed; \n    top: 0; \n    left: 0; \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 30px;\n    padding-top: 36px;\n}\n\n.div_menu_generalImg{\n    width: 40px;\n}\n\n.textDecorationNone{\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
