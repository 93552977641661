// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_branco_cinza{
    margin:0;
    border-radius: 10px;
    font-size: 15px;
    padding-left:10px;
    height: 50px;
    width:100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/inputbrancocinzagrande.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,UAAU;AACd","sourcesContent":[".input_branco_cinza{\n    margin:0;\n    border-radius: 10px;\n    font-size: 15px;\n    padding-left:10px;\n    height: 50px;\n    width:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
